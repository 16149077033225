
.featuredCollections {
    width: 100%;
    height: 100%;

}

.featuredCollections .swiper-pagination-bullet {
    background: #7A7E92;
}

.featuredCollections .swiper-pagination-bullet-active {
        background-color: #944DFF
}
.featuredCollections .swiper-button-next, .swiper-button-prev {
    color: #944DFF
}
.featuredCollections .swiper-button-next:after, .swiper-button-prev:after {
     font-size: 20px;
}
.featuredCollections .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}



 .featuredCollections .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.featuredCollections .swiper-button-prev, .featuredCollections .swiper-button-next {
    width: 40px;
    height: 40px;
    color: white;
    background-color: #1B1E2F;
    position: absolute;
    top: 50%;
    z-index: 2;
    border-radius: 50%;
    transform: translateY(-50%);
    box-shadow: rgb(0 0 0 / 15%) 0px 4px 10px;
}
.featuredCollections .swiper-button-prev:hover, .featuredCollections .swiper-button-next:hover {
    background-color: #944dff;
    box-shadow: rgb(0 0 0 / 25%) 0px 4px 10px;

}

@media only screen and (max-width: 800px) {
    .featuredCollections .swiper-slide img {
       height: 400px !important;
        border-radius: 0px;
    }
    .featuredCollections .swiper-button-prev, .featuredCollections .swiper-button-next {
        display: none;
    }
}