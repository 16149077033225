
.desktopExplorePreview {
    width: 100%;
    height: 100%;
}

.desktopExplorePreview .swiper-pagination-bullet {
    background: #7A7E92;
}

.desktopExplorePreview .swiper-pagination-bullet-active {
        background-color: #944DFF
}
.desktopExplorePreview .swiper-button-next, .swiper-button-prev {
    color: #944DFF
}
.desktopExplorePreview .swiper-button-next:after, .swiper-button-prev:after {
     font-size: 20px;
}
 .desktopExplorePreview .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #0f1224;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}



 .desktopExplorePreview .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.desktopExplorePreview .swiper-button-prev, .desktopExplorePreview .swiper-button-next {
    width: 40px;
    height: 40px;
    color: white;
    background-color: #1B1E2F;
    position: absolute;
    top: 50%;
    z-index: 2;
    border-radius: 50%;
    transform: translateY(-50%);
    box-shadow: rgb(0 0 0 / 15%) 0px 4px 10px;
}
.desktopExplorePreview .swiper-button-prev:hover, .desktopExplorePreview .swiper-button-next:hover {
    background-color: #944dff;
    box-shadow: rgb(0 0 0 / 25%) 0px 4px 10px;

}

@media only screen and (max-width: 800px) {

    .desktopExplorePreview .swiper-button-prev, .desktopExplorePreview .swiper-button-next {
        display: none;
    }
}