/*body {*/
/*  margin: 0;*/
/*  font-family: 'Quantico', sans-serif;*/

/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*  monospace;*/
/*}*/

a {
    text-decoration: none;
}


  @keyframes glow {
    0% { 
        box-shadow: 0px 30px 80px rgb(138 38 255 / 40%); 
    
    }
    50% { 
        box-shadow: 0px 30px 80px rgb(138 38 255 / 20%); 
      
    }
    100% { 
        box-shadow: 0px 30px 80px rgb(138 38 255 / 40%); 
        
    }
  }