:root {
    --swiper-image-ratio: 33.3%; /* ratio 16:9 = 56.25% */

    --swiper-width: 50%;
    --swiper-inactive-scale: .85; /* makes the other slide smaller */

    /* responsive settings */
    --swiper-mobile-width: 90%;
    --swiper-mobile-inactive-scale: .95;
}
.tokens-slider {
    width: 100%;
    height: 200px;
}

.tokens-slider .swiper-slide {
    opacity: .5;
    transform: scale(.84);
    transition: all .3s ease-in-out;
}



.tokens-slider .swiper-slide.swiper-slide-active {
    transform: scale(1) !important;
    opacity: 1 !important;
}

.tokens-slider .swiper-button-prev, .tokens-slider .swiper-button-next {
    width: 40px;
    height: 40px;
    color: white;
    background-color: #1B1E2F;
    position: absolute;
    top: 50%;
    z-index: 2;
    border-radius: 50%;
    transform: translateY(-50%);
    box-shadow: rgb(0 0 0 / 15%) 0px 4px 10px;
}

.tokens-slider .swiper-button-prev:hover, .tokens-slider .swiper-button-next:hover {
    background-color: #944dff;
    box-shadow: rgb(0 0 0 / 25%) 0px 4px 10px;

}

.tokens-slider .swiper-backface-hidden .swiper-slide.swiper-slide-active {
    transform: scale(1) translateZ(0) !important;
}

.tokens-slider .swiper-image {
    position: relative;
    width: 100%;
    padding-top: var(--swiper-image-ratio);
}

.tokens-slider .swiper-image .image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 200px !important;

}

.tokens-slider .swiper-button-next, .swiper-button-prev {
    color: #944DFF
}
.tokens-slider .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 20px;
}

.tokens-slider .swiper-button-next::after,
.tokens-slider .swiper-button-prev::after {
    color: #fff;
    font-size: 12px;
}




.tokens-slider .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #944dff;
    opacity: 1;
    background: #333645;
}

.tokens-slider .swiper-pagination-bullet-active {
    color: #fff;
    background: #944dff;
    width: 12px;
    height: 12px;
}

@media only screen and (max-width: 800px) {

    .tokens-slider .swiper-button-prev, .tokens-slider .swiper-button-next {
        display: none;
    }
    .tokens-slider{
        height: 150px;
    }
}



